import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import AOS from "aos";
import "aos/dist/aos.css";
import "@mdi/font/css/materialdesignicons.min.css";

import "./assets/css/styles.css";

Vue.use(AOS.init());

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
